import fastdom from "fastdom";
export function allCriterionAndTests() {
  var toggleAttribute = function toggleAttribute(el) {
    return el === "true" || "" ? "false" : "true";
  };
  var allCriterion = document.querySelector("#allCriterion");
  var allTests = document.querySelector("#allTests");
  var displayAllCriterion = function displayAllCriterion(event) {
    var containers = document.querySelectorAll(".criteres > div > section ");
    containers.forEach(function (container) {
      var button = container.children[0];
      var disabled = allTests === null || allTests === void 0 ? void 0 : allTests.getAttribute("disabled");
      if (disabled) allTests === null || allTests === void 0 || allTests.removeAttribute("disabled");else {
        allTests === null || allTests === void 0 || allTests.setAttribute("disabled", "true");
      }
      fastdom.mutate(function () {
        button.setAttribute("aria-expanded", event.target.checked);
      });
    });
  };
  function displayAllTests(event) {
    var containers = document.querySelectorAll(" .tests .methodologie");
    containers.forEach(function (container) {
      var button = container.children[0];
      fastdom.mutate(function () {
        button.setAttribute("aria-expanded", event.target.checked);
      });
    });
  }
  if (allCriterion) {
    allCriterion === null || allCriterion === void 0 || allCriterion.addEventListener("change", displayAllCriterion);
  }
  if (allTests && !allTests.getAttribute("disabled")) {
    allTests.addEventListener("change", displayAllTests);
  }
  /*
  	document.addEventListener('DOMContentLoaded', function () {
  		displayAllCriterion()
  		displayAllTests()
  	})*/
}
allCriterionAndTests();
export function init() {
  // Handle the 3 filters in criteria page
  var filtersEl = document.querySelector("#criteria-filters");
  var criteriaContainerEl = document.querySelector("#criteria-container");
  if (filtersEl && criteriaContainerEl) {
    var criteriaContainerParentEl = criteriaContainerEl.parentElement;
    var buttonElms = criteriaContainerEl.getElementsByTagName("button");
    var liEls = criteriaContainerParentEl.querySelectorAll("li.criterion");

    // filtering happens under "criteria-filters" element
    filtersEl.addEventListener("change", function (e) {
      var curEl = e.target;
      var filter = curEl.dataset.filter;
      var checked = curEl.checked;
      switch (filter) {
        case "all":
          var buttons = Array.from(buttonElms);
          var _loop = function _loop(_button) {
            _button = buttons[i];
            fastdom.mutate(function () {
              _button.setAttribute("aria-expanded", checked);
            });
            button = _button;
          };
          for (var il = buttons.length, i = il - 1, button; i >= 0; --i) {
            _loop(button);
          }
          break;
        case "A":
        case "AA":
          var checkedFilters = Array.from(filtersEl.querySelectorAll("input:checked"));
          checkedFilters = checkedFilters.map(function (el) {
            return el.dataset.filter;
          });
          var lis = Array.from(liEls);
          lis.forEach(function (el) {
            var arr = el.dataset.wcagLevel.split(",");
            if (!arr.filter(function (el) {
              return checkedFilters.includes(el);
            }).length) {
              fastdom.mutate(function () {
                el.classList.add("fr-hidden");
              });
            } else {
              fastdom.mutate(function () {
                el.classList.remove("fr-hidden");
              });
            }
          });
          break;
        default:
          // should never happen
          break;
      }
    });
  }
}
init();